import { connect } from 'react-redux'

import {
  addDriverToDepot,
  changeDriverHistoryDate,
  clearDrivers,
  setFocusedDriversDriver,
  updateDriverDepot,
  updateDriverDisabled
} from 'src/redux/drivers/operations'
import {
  setDriverDetailsContent,
  setFocusedDriverDepotHasMETRCRules,
  toggleDriverMETRCInfoDrawer
} from 'src/redux/drivers/actions'
import { fetchSpecificDepotDrivers } from 'src/redux/dispensaries/actions'
import { getActiveDispensariesMap } from 'src/redux/dispensaries/selectors'
import {
  getDriverDetailsContent,
  getDriverMETRCInfoDrawerOpen,
  getFocusedDriver,
  getFocusedDriverDepotHasMETRCRules,
  getFocusedDriverDepotId,
  getFocusedDriverId,
  getFocusedDriverInventory,
  getFocusedDriverOnfleetId,
  getFocusedDriverOrders,
  getIsDriverFocused,
  getIsFocusedDriverInventoryLoading,
  getIsFocusedDriverOrdersLoading
} from 'src/redux/drivers/selectors'
import { updateDriverInventory } from 'src/redux/driver-inventory/actions'
import { getIsAdmin } from 'src/redux/permissions/selectors'
import {
  getCasesByDepot,
  assignCaseTemplateToDrivers,
  setCaseUnassigned
} from 'src/redux/cases/actions'
import { getCases } from 'src/redux/cases/selectors'
import { idToInt } from 'helpers/sanitizers'
import {
  changeDriverMode,
  filterDriverMapByDepotId,
  setDriverDeliveryType
} from 'src/redux/driver-map/operations'
import { getDriverMap } from 'src/redux/driver-map/selectors'
import { getDepotsMap } from 'src/redux/depots-map/selectors'
import {
  getDriverCurrentVehicle,
  getDriverVehicles
} from 'src/redux/driver-vehicles/selectors'
import {
  createDriverVehicle,
  deleteDriverVehicle,
  updateDriverVehicle
} from 'src/redux/driver-vehicles/operations'
import { pushNotification } from 'src/redux/alerts/actions'
import { rerouteOrder } from 'src/redux/orders/actions'
import DriversComponent from './'

const mapStateToProps = (state, props) => {
  const { id } = props.match.params
  const { depots, loading } = state
  const selectedDepotId = idToInt(id || depots.id)

  const drivers = getDriverMap(state)
  const focusedDriverDepotId = getFocusedDriverDepotId(state)
  const depotsMap = getDepotsMap(state)
  const focusedDriverDepot = depotsMap[focusedDriverDepotId] || {}

  const dispensariesMap = getActiveDispensariesMap(state)
  const focusedDriverDispensary = focusedDriverDepot.dispensaryId ? dispensariesMap[focusedDriverDepot.dispensaryId] : {}
  const isDriverFocused = getIsDriverFocused(state)
  return {
    cases: getCases(state),
    depotId: selectedDepotId,
    depots: depots.list,
    depotsMap,
    driverDetailsContent: getDriverDetailsContent(state),
    drivers: filterDriverMapByDepotId(drivers, selectedDepotId),
    driverCurrentVehicle: getDriverCurrentVehicle(state),
    driverMETRCInfoDrawerOpen: getDriverMETRCInfoDrawerOpen(state),
    driverVehicles: getDriverVehicles(state),
    focusedDriver: getFocusedDriver(state),
    focusedDriverDepotName: focusedDriverDepot.name,
    focusedDriverDispensary,
    focusedDriverOnfleetId: getFocusedDriverOnfleetId(state),
    focusedDriverId: getFocusedDriverId(state),
    focusedDriverInventory: getFocusedDriverInventory(state),
    focusedDriverOrders: getFocusedDriverOrders(state),
    isAdmin: getIsAdmin(state),
    isDriverFocused,
    isFocusedDriverInventoryLoading: getIsFocusedDriverInventoryLoading(state),
    isFocusedDriverOrdersLoading: getIsFocusedDriverOrdersLoading(state),
    loading: loading.drivers,
    showVehicleFunctionality: getFocusedDriverDepotHasMETRCRules(state),
    userEmail: state.user.email // this is from shared-redux, at some point we need to create selectors for user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    addDriverToDepot: (driverEmail, depotId) => dispatch(addDriverToDepot(driverEmail, depotId)),
    assignCaseTemplateToDrivers: (id, name, drivers, depotId) =>
      dispatch(assignCaseTemplateToDrivers(id, name, drivers, depotId)),
    changeDriverHistoryDate: (driver, date) =>
      dispatch(changeDriverHistoryDate(driver, date)),
    changeDriverMode: (value, driverId) =>
      dispatch(changeDriverMode(value, driverId)),
    clearDrivers: () => dispatch(clearDrivers()),
    createDriverVehicle: (payload, onfleetWorkerId) => dispatch(createDriverVehicle(payload, onfleetWorkerId)),
    deleteDriverVehicle: (driverId, vehicleId, onfleetWorkerId) =>
      dispatch(deleteDriverVehicle(driverId, vehicleId, onfleetWorkerId)),
    fetchSpecificDepotDrivers: id => dispatch(fetchSpecificDepotDrivers(id)),
    getCasesByDepot: id => dispatch(getCasesByDepot(id)),
    pushNotification: (message, type) =>
      dispatch(pushNotification(message, type)),
    setCaseUnassigned: (drivers, depotId) =>
      dispatch(setCaseUnassigned(drivers, depotId)),
    setDriverDeliveryType: (driverId, deliveryType) =>
      dispatch(setDriverDeliveryType(driverId, deliveryType)),
    setDriverDetailsContent: contentKey =>
      dispatch(setDriverDetailsContent(contentKey)),
    setFocusedDriversDriver: id => dispatch(setFocusedDriversDriver(id)),
    resetFocusedDriverDepotHasMETRCRules: () =>
      dispatch(setFocusedDriverDepotHasMETRCRules(undefined)),
    updateDriverDepot: (driverId, depotId, parentDispensaryId) =>
      dispatch(updateDriverDepot(driverId, depotId, parentDispensaryId)),
    toggleDriverMETRCInfoDrawer: () => dispatch(toggleDriverMETRCInfoDrawer()),
    updateDriverInventory: (driverId, inventory, inventoryChecksum) =>
      dispatch(updateDriverInventory(driverId, inventory, inventoryChecksum)),
    updateDriverVehicle: (driverId, vehicleId, payload, onfleetWorkerId) =>
      dispatch(updateDriverVehicle(driverId, vehicleId, payload, onfleetWorkerId)),
    rerouteOrder: (orderId, newRoute) => dispatch(rerouteOrder(orderId, newRoute)),
    updateDriverDisabled: (driverId) => dispatch(updateDriverDisabled(driverId))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriversComponent)
