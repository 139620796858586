import React, { PureComponent } from 'react'
import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string
} from 'prop-types'
import window from 'global/window'
import Button, { TRANSPARENT, HYBRID } from 'components/button'
import sortOn from 'sort-on'
import fuzzysearch from 'fuzzysearch'
import Drawer from '@eaze/react-fast-drawer'
import { cx } from 'emotion'
import Breakdown from 'components/breakdown'
import AddDriver from './add-driver'
import DriverMETRCInfo from 'src/components/breakdown/driver-metrc-info'
import FabFan from 'src/components/fab-fan'
import Overlay from 'src/components/overlay'
import RightBar from 'src/components/right-bar'
import TableSearch from 'microcomponents/table-pager/search'
import Check from 'assets/check.svg'
import UncheckedBox from 'assets/checkMarkOff.svg'
import {
  CLOSE_DRIVER_DRAWER,
  DEFAULT_DRIVER_CONTENT_TAB,
  DRIVER_ENUMS,
  windowConfirmUnsavedChanges
} from 'helpers/drivers'
import { dateOnly } from 'src/helpers/date'
import { MC_URL } from 'helpers/environment'

import {
  blueDot,
  bottomBar,
  clickableText,
  controls,
  customerTable,
  disabledRow,
  drawerContainer,
  drivers as driversClass,
  greenDot,
  header,
  isSus,
  leftContent,
  myCheckbox,
  noMatches,
  redDot,
  rightContent,
  select,
  selectWrapper,
  yellowDot
} from './style.js'

export default class DriversComponent extends PureComponent {
  static propTypes = {
    addDriverToDepot: func,
    assignCaseTemplateToDrivers: func,
    cases: arrayOf(object).isRequired,
    changeDriverHistoryDate: func,
    changeDriverMode: func,
    clearDrivers: func,
    createDriverVehicle: func,
    deleteDriverVehicle: func,
    depotId: number.isRequired,
    depots: array,
    depotsMap: object,
    drivers: array.isRequired,
    driverCurrentVehicle: object,
    driverDetailsContent: string,
    driverMETRCInfoDrawerOpen: bool,
    driverVehicles: arrayOf(object),
    fetchSpecificDepotDrivers: func,
    focusedDriver: shape({ location: object }),
    focusedDriverDepotName: string,
    focusedDriverDispensary: object,
    focusedDriverId: oneOfType([string, number]),
    focusedDriverOnfleetId: string,
    focusedDriverInventory: shape({
      inventory: array,
      inventoryChecksum: object
    }),
    focusedDriverOrders: array,
    getCasesByDepot: func,
    isAdmin: bool,
    isDriverFocused: bool,
    isFocusedDriverInventoryLoading: bool,
    isFocusedDriverOrdersLoading: bool,
    loading: bool,
    pushNotification: func,
    resetFocusedDriverDepotHasMETRCRules: func,
    setCaseUnassigned: func,
    setDriverDeliveryType: func,
    setDriverDetailsContent: func,
    setFocusedDriversDriver: func,
    showVehicleFunctionality: bool,
    toggleDriverMETRCInfoDrawer: func,
    updateDriverDepot: func,
    userEmail: string,
    updateDriverInventory: func,
    updateDriverVehicle: func,
    rerouteOrder: func,
    updateDriverDisabled: func,
  }

  state = {
    didInventoryChange: false,
    drivers: [],
    isAddDriverDrawerOpen: false,
    query: '',
    selectedCase: false,
    selectedDrivers: {},
    showVehicleFunctionality: false,
    sortKey: ''
  }

  componentDidMount () {
    const {
      clearDrivers,
      getCasesByDepot,
      fetchSpecificDepotDrivers,
      depotId
    } = this.props
    clearDrivers()
    getCasesByDepot(depotId)
    fetchSpecificDepotDrivers(depotId)
    document.addEventListener('keydown', this.handleKeyDown, false)
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    if (this.props.drivers !== nextProps.drivers) {
      this.setState({ drivers: nextProps.drivers })
    }
  }

  componentWillUnmount () {
    const { clearDrivers } = this.props

    clearDrivers()
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  toCustomer = id => {
    const userProfileUrl = `${MC_URL}/profile/${id}`
    window.location = userProfileUrl
  }

  onSelectDriver = (id, isChecked) => {
    const { selectedDrivers } = this.state
    const newSelectedDrivers = { ...selectedDrivers }

    if (isChecked) {
      newSelectedDrivers[id] = true
    } else {
      delete newSelectedDrivers[id]
    }
    this.setState({ selectedDrivers: newSelectedDrivers })
  }

  handleSelectDriver = event => {
    event.stopPropagation()
    const {
      target: { value, checked }
    } = event
    this.onSelectDriver(value, checked)
  }

  numberOfSelectedDrivers = () => {
    return Object.keys(this.state.selectedDrivers).length
  }

  handleSelectCase = e => {
    const caseId = e.target.value
    this.setState({ selectedCase: caseId })
  }

  saveChanges = () => {
    const { selectedCase, selectedDrivers } = this.state
    const { assignCaseTemplateToDrivers, cases, depotId, setCaseUnassigned } = this.props
    const drivers = Object.keys(selectedDrivers).map(Number)
    if (selectedCase === 'UNASSIGNED') {
      setCaseUnassigned(drivers, depotId)
    } else {
      const caseTemplate = cases.find(c => c.id === selectedCase)
      assignCaseTemplateToDrivers(
        selectedCase,
        caseTemplate.name,
        drivers,
        depotId
      )
    }
    this.setState({ selectedDrivers: {} })
  }

  setText = event => {
    const { value } = event.target
    if (!value || value.trim().length < 1) {
      this.reset()
    } else {
      this.setState({ drivers: this.searchDriverFilter(value), query: value })
    }
  }

  searchDriverFilter = filteredString => {
    const { drivers } = this.props

    if (filteredString === '' || filteredString === null) {
      return drivers
    }

    const filteredDrivers = drivers.filter(function (driverObject) {
      const driver = driverObject || {}
      const {
        firstName = '',
        lastName = '',
        id = '',
        caseTemplateName = ''
      } = driver

      const searchContentString = `${firstName} ${lastName} ${id} ${caseTemplateName} ${driverObject}`

      return fuzzysearch(
        filteredString.toLowerCase(),
        searchContentString.toLowerCase()
      )
    })

    return filteredDrivers
  }

  submit = event => {
    const { query } = this.state
    event.preventDefault()
    this.setState({ drivers: this.searchDriverFilter(query) })
  }

  reset = () => {
    const { drivers } = this.props
    this.setState({ drivers, query: '' })
  }

  selectAll = () => {
    const { drivers } = this.state
    const newSelectedDrivers = {}
    drivers.forEach(driver => {
      if (!(driver.driverMode !== 0 || driver.isSuspended)) {
        newSelectedDrivers[driver.id] = true
      }
    })
    this.setState({ selectedDrivers: newSelectedDrivers })
  }

  deselectAll = () => {
    this.setState({ selectedDrivers: {} })
  }

  sortDrivers = type => event => {
    const { drivers, sortKey } = this.state
    if (!drivers && !drivers.length) return

    const sort = sortKey === type ? `-${type}` : type
    const sortedDrivers = sortOn(drivers, sort)

    this.setState({ drivers: sortedDrivers, sortKey: sort })
  }

  openDriverDetail = driver => event => {
    const { changeDriverHistoryDate, setFocusedDriversDriver } = this.props
    setFocusedDriversDriver(driver.id)
    const today = dateOnly()
    changeDriverHistoryDate(driver.id, today)
  }

  resetTabAndCloseDrawer = () => {
    const {
      resetFocusedDriverDepotHasMETRCRules,
      setDriverDetailsContent,
      setFocusedDriversDriver
    } = this.props
    setDriverDetailsContent(DEFAULT_DRIVER_CONTENT_TAB)
    setFocusedDriversDriver(CLOSE_DRIVER_DRAWER)
    resetFocusedDriverDepotHasMETRCRules()
  }

  handleKeyDown = e => {
    const { isDriverFocused } = this.props

    if (isDriverFocused && e.which === 27) {
      this.promptUnsavedChanges()
    }
  }

  promptUnsavedChanges = () => {
    const { didInventoryChange } = this.state

    if (didInventoryChange) {
      if (windowConfirmUnsavedChanges()) {
        this.trackInventoryChanges([])
        this.resetTabAndCloseDrawer()
      }
    } else {
      this.resetTabAndCloseDrawer()
    }
  }

  handleRequestClose = e => {
    this.promptUnsavedChanges()
  }

  trackInventoryChanges = changes => {
    if (changes.length) {
      this.setState({ didInventoryChange: true })
    } else {
      this.setState({ didInventoryChange: false })
    }
  }

  handleAddDriverFabClick = () => {
    this.setState(prevState => ({
      isAddDriverDrawerOpen: !prevState.isAddDriverDrawerOpen
    }))
  }

  filterOrdersByDispensary = (orders, dispensaryId) => orders.filter(order => order.dispensaryId === dispensaryId)

  render () {
    const {
      drivers,
      isAddDriverDrawerOpen,
      selectedDrivers
    } = this.state
    const {
      addDriverToDepot,
      cases,
      changeDriverHistoryDate,
      changeDriverMode,
      createDriverVehicle,
      deleteDriverVehicle,
      depotId,
      depots,
      depotsMap,
      driverDetailsContent,
      driverCurrentVehicle,
      driverMETRCInfoDrawerOpen,
      driverVehicles,
      focusedDriver,
      focusedDriverDepotName,
      focusedDriverDispensary,
      focusedDriverId,
      focusedDriverInventory,
      focusedDriverOrders,
      focusedDriverOnfleetId,
      isAdmin,
      isDriverFocused,
      isFocusedDriverInventoryLoading,
      isFocusedDriverOrdersLoading,
      loading,
      pushNotification,
      setDriverDeliveryType,
      setDriverDetailsContent,
      setFocusedDriversDriver,
      showVehicleFunctionality,
      toggleDriverMETRCInfoDrawer,
      updateDriverDepot,
      userEmail,
      updateDriverInventory,
      updateDriverVehicle,
      rerouteOrder,
      updateDriverDisabled
    } = this.props

    const { depotId: focusedDriverDepotId, driverMode } = focusedDriver

    const numSelectedDrivers = this.numberOfSelectedDrivers()
    const areDriversFound = drivers && drivers.length && !loading

    return (
      <div className={`${driversClass} DriversComponent`}>
        <div className={controls}>
          <TableSearch placeholderText='Search Drivers' setText={this.setText} submit={this.submit} />
        </div>
        {areDriversFound ? (
              <table className={customerTable}>
                <thead>
                  <tr>
                    <th aria-label='checkbox-header' />
                    <th>
                      <button
                        onClick={this.sortDrivers('firstName')}
                        className={header}
                      >
                        DRIVER NAME
                      </button>
                    </th>
                    <th>
                      <button
                        onClick={this.sortDrivers('id')}
                        className={header}
                      >
                        DRIVER ID
                      </button>
                    </th>
                    <th>
                      <button
                        onClick={this.sortDrivers('totalDriverOrdersCount')}
                        className={header}
                      >
                        ORDERS
                      </button>
                    </th>
                    <th>
                      <button
                        onClick={this.sortDrivers('caseTemplateName')}
                        className={header}
                      >
                        CASE
                      </button>
                    </th>
                    <th>
                      <button
                        onClick={this.sortDrivers('driverMode')}
                        className={header}
                      >
                        STATUS
                      </button>
                    </th>
                    <th>
                      <button
                        onClick={this.sortDrivers('deliveryType')}
                        className={header}
                      >
                        DELIVERY TYPE
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {drivers.map(driver => {
                    const rowStyle = cx([
                      Boolean(driver.isSuspended) && isSus,
                      driver.driverMode !== 0 && disabledRow
                    ])

                    const statusStyle = cx([
                      driver.driverMode === 0 && redDot,
                      driver.driverMode === 1 && blueDot,
                      driver.driverMode === 2 && greenDot,
                      driver.driverMode === 3 && yellowDot
                    ])

                    return (
                      <tr
                        onClick={this.openDriverDetail(driver)}
                        className={rowStyle}
                        key={driver.id}
                      >
                        <td onClick={this.handleSelectDriver}>
                          <label className={myCheckbox}>
                            <input
                              type='checkbox'
                              onChange={this.handleSelectDriver}
                              checked={selectedDrivers[driver.id] || false}
                              disabled={driver.driverMode !== 0}
                              value={driver.id}
                              id={`name_${driver.id}`}
                            />
                            <span />
                          </label>
                        </td>
                        <td className='fs-hide'>
                          {driver.firstName} {driver.lastName}
                        </td>
                        <td>{driver.id}</td>
                        <td>{driver.totalDriverOrdersCount}</td>
                        <td>{driver.caseTemplateName}</td>
                        <td>
                          <div className={statusStyle} />
                          {DRIVER_ENUMS[driver.driverMode]}
                        </td>
                        <td>{driver.deliveryType}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
        ) : (
            <div className={noMatches}>
              <div>😳</div>
              <div style={{ marginBottom: '1rem' }}>No matches</div>
              <Button onClick={this.reset} type={TRANSPARENT}>
                Try Again
              </Button>
            </div>
        )}

        <div className={bottomBar}>
          {numSelectedDrivers > 0 ? (
              <div className={leftContent}>
                <img
                  onClick={this.deselectAll}
                  style={{ height: '2rem', width: '3rem' }}
                  src={Check}
                />
                <div style={{ paddingLeft: '1rem' }}>
                  {numSelectedDrivers}{' '}
                  {numSelectedDrivers === 1 ? 'Driver' : 'Drivers'} Selected
                </div>
                <div onClick={this.deselectAll} className={clickableText}>
                  Deselect All
                </div>
              </div>
          ) : (
              <div onClick={this.selectAll} className={leftContent}>
                <img
                  style={{ height: '2rem', width: '3rem' }}
                  src={UncheckedBox}
                />
                <div className={clickableText}>Select All</div>
              </div>
          )}
          <div className={rightContent}>
            <div className={selectWrapper}>
              <select
                onChange={this.handleSelectCase}
                /* We were getting React console warnings when value={null}.
                React prefers undefined or empty string.
                Setting value={''} breaks case assignment but value={undefined} works. */
                value={this.state.selectedCase}
                className={select}
              >
                <option value={false} disabled>
                  Select Case
                </option>
                {cases.map(caseTemplate => (
                  <option key={caseTemplate.id} value={caseTemplate.id}>
                    {caseTemplate.name}
                  </option>
                ))}
                <option key='UNASSIGNED' value='UNASSIGNED'>
                  UNASSIGNED
                </option>
              </select>
            </div>
            <Button
              onClick={this.saveChanges}
              type={HYBRID}
              componentStyle={{ marginLeft: '2rem', minWidth: '12rem' }}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
        <Drawer
          open={isDriverFocused}
          onRequestClose={this.handleRequestClose}
          modalElementClass={drawerContainer}
          dontApplyListeners
        >
          <Breakdown
            changeDriverHistoryDate={changeDriverHistoryDate}
            changeDriverMode={changeDriverMode}
            didInventoryChange={this.state.didInventoryChange}
            driverCurrentVehicle={driverCurrentVehicle}
            driverDetailsContent={driverDetailsContent}
            driverInventoryInfo={focusedDriverInventory}
            driverMETRCInfoDrawerOpen={driverMETRCInfoDrawerOpen}
            driverOrders={this.filterOrdersByDispensary(focusedDriverOrders, focusedDriverDispensary.id)}
            focusedDriver={focusedDriver}
            focusedDriverDepotName={focusedDriverDepotName}
            focusedDriverId={focusedDriverId}
            isAdmin={isAdmin}
            isInventoryLoading={isFocusedDriverInventoryLoading}
            isOrdersLoading={isFocusedDriverOrdersLoading}
            orderHistoryDrawer
            pushNotification={pushNotification}
            setDriverDetailsContent={setDriverDetailsContent}
            setDriverDeliveryType={setDriverDeliveryType}
            setFocusedDriver={setFocusedDriversDriver}
            showDriverMETRCInfoDrawerToggle
            toggleDriverMETRCInfoDrawer={toggleDriverMETRCInfoDrawer}
            trackInventoryChanges={this.trackInventoryChanges}
            updateDriverInventory={updateDriverInventory}
            userEmail={userEmail}
            rerouteOrder={rerouteOrder}
          />
        </Drawer>
        {!isDriverFocused &&
          <FabFan
            options={[]}
            onClick={this.handleAddDriverFabClick}
          />}
        <RightBar isOpen={driverMETRCInfoDrawerOpen || isAddDriverDrawerOpen}>
          <div>
            {driverMETRCInfoDrawerOpen && (
              <DriverMETRCInfo
                closeDrawer={toggleDriverMETRCInfoDrawer}
                createDriverVehicle={createDriverVehicle}
                currentVehicle={driverCurrentVehicle}
                deleteDriverVehicle={deleteDriverVehicle}
                depotId={focusedDriverDepotId}
                depots={depotsMap}
                dispensaryId={focusedDriverDispensary.id}
                driverId={focusedDriverId}
                driverMode={driverMode}
                showVehicleFunctionality={showVehicleFunctionality}
                onfleetWorkerId={focusedDriverOnfleetId}
                updateDriverDepot={updateDriverDepot}
                updateDriverVehicle={updateDriverVehicle}
                vehicles={driverVehicles}
                updateDriverDisabled={updateDriverDisabled}
              />
            )}
            {isAddDriverDrawerOpen && (
              <AddDriver
                addDriverToDepot={addDriverToDepot}
                closeDrawer={this.handleAddDriverFabClick}
                depots={depots}
                preselectedDepotId={depotId}
              />
            )}
          </div>
        </RightBar>
        <Overlay
          isOpen={driverMETRCInfoDrawerOpen || isAddDriverDrawerOpen}
          onClick={this.handleAddDriverFabClick}
        />
      </div>
    )
  }
}
